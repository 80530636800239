<template>
  <form ref="form" @submit.prevent="send">
    <div>
      <h2>Контактные данные</h2>
      <div class="form">
        <p v-if="design === 'individual'" class="text">
          Оставьте контактные данные, чтобы мы смогли связаться с вами и начать
          работу по разработке дизайна
        </p>
        <p v-else-if="design === 'your'" class="text">
          Оставьте контактные данные и загрузите дизайн, чтобы мы смогли
          связаться с вами и начать работу по проекту
        </p>
        <p v-else-if="design === 'construct'" class="text">
          Оставьте контактные данные, чтобы мы смогли связаться с вами и начать
          работу по проекту
        </p>
        <div class="fill_in">
          <div class="left">
            <ul class="data">
              <li>
                <span><span style="color: red">*</span> ИМЯ</span>
                <input name="name" type="text" placeholder="Имя" required />
              </li>
              <li>
                <span>КОМПАНИЯ</span>
                <input name="company" type="text" placeholder="Название" />
              </li>
              <li>
                <span><span style="color: red">*</span> E-MAIL</span>
                <input
                  name="email"
                  type="email"
                  placeholder="E-mail"
                  required
                />
              </li>
              <li>
                <span><span style="color: red">*</span> ТЕЛЕФОН</span>
                <div class="number">
                  <label for="telContact">+7</label>
                  <input
                    name="phone"
                    type="tel"
                    placeholder="XXX XXX-XX-XX"
                    required
                  />
                </div>
              </li>
              <li>
                <span><span style="color: red">*</span> ГОРОД</span>
                <input name="city" type="text" placeholder="ГОРОД" required />
              </li>
            </ul>
          </div>
          <div class="right">
            <template v-if="design === 'your'">
              <div class="upload">
                <label class="btn btnMaim btnMaim_nona">
                  Загрузить свой дизайн
                  <input
                    name="file"
                    type="file"
                    style="visibility: hidden"
                    placeholder="Загрузить свой дизайн"
                  />
                </label>
                <p>
                  Вы можете загрузить файл в формате jpg, png, pdf, eps, cdr.
                  Вес не более 12 Мб.
                </p>
              </div>
            </template>
            <template v-else-if="design === 'construct'">
              <CarCanvas class="dn" @ready="createImages" />
              <div id="views"></div>
            </template>
            <div class="request">
              <p class="textarea">Комментарий к заказу</p>
              <textarea
                name="question"
                placeholder="Начните писать текст"
                @keydown.enter.exact.prevent="send"
              ></textarea>
              <span
                ><span style="color: red">*</span> Обязательные поля для
                заполнения</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      type="submit"
      class="btn btnMaim nonaMain btnMaim_wide"
      :class="{
        disabled: isSent,
      }"
    >
      {{ isSent ? "Отправлено" : "Отправить и скачать"
      }}<i v-show="!isSent" class="far fa-long-arrow-right"></i>
    </button>
    <div v-show="isSent" class="btn btnLink" @click="download">
      Скачать изображения
      <img v-svg-inline src="@/assets/imgs/download2.svg" alt="" />
    </div>
  </form>
</template>

<script>
import axios from "axios";
import { jsPDF } from "jspdf";

import { mapFields } from "vuex-map-fields";

import CarCanvas from "../constructor-car/__car-canvas__";
import config from "@/config";

export default {
  components: {
    CarCanvas,
  },
  data() {
    return {
      init: {
        carIndx: 0,
        viewId: "left",
      },
      viewIndx: 0,
      blobs: [],
      blobNames: [],
      isSent: false,
    };
  },
  computed: {
    ...mapFields(["design", "cars", "carIndx", "viewId"]),
    orderType() {
      switch (this.design) {
        case "individual":
          return "INDIVIDUAL_DESIGN";
        case "your":
          return "MY_DESIGN";
        case "construct":
          return "CONSTRUCTOR";
        default:
          return "UNKNOWN";
      }
    },
    countCars() {
      return this.cars.length;
    },
    countViews() {
      return this.cars.reduce(
        (a, c) => (a += Object.keys(c.views ?? {}).length),
        0
      );
    },
    viewIds() {
      return Object.keys(this.cars[this.carIndx].views ?? {});
    },
  },
  created() {
    this.init = {
      carIndx: this.carIndx,
      viewId: this.viewId,
    };
    this.carIndx = 0;
    this.viewIndx = 0;
    this.viewId = this.viewIds[this.viewIndx];
  },
  beforeDestroy() {
    this.restore();
  },
  methods: {
    download() {
      const doc = new jsPDF();
      let y = 0;
      this.blobs.forEach((blob) => {
        const img = new Image();
        img.src = URL.createObjectURL(blob);
        doc.addImage(img, "png", 10, y * 100, 200, 100);
        y++;
        if (y > 2) {
          doc.addPage();
          y = 0;
        }
      });

      doc.save("doc.pdf");
    },
    restore() {
      this.carIndx = this.init.carIndx;
      this.viewId = this.init.viewId;
    },
    async createImages() {
      if (this.blobs.length >= this.countViews) return;
      const canvas = document.getElementById("c");

      const views = document.getElementById("views");
      canvas.toBlob((blob) => {
        this.blobs.push(blob);

        const img = new Image(267, 133);
        const imgURL = window.URL.createObjectURL(blob);
        img.src = imgURL;
        views.appendChild(img);
        this.blobNames.push(`${this.viewId}_${this.carIndx + 1}`);

        if (this.viewIndx < this.viewIds.length - 1) {
          this.viewId = this.viewIds[++this.viewIndx];
        } else if (this.carIndx < this.cars.length - 1) {
          this.carIndx++;
          this.viewIndx = 0;
          this.viewId = this.viewIds[this.viewIndx];
        } else {
          this.restore();
        }
      });
    },
    async send() {
      const formData = new FormData(this.$refs.form);
      formData.append("from", this.orderType);

      let total_price = 0;
      let total_days = 0;

      this.cars.forEach((car, index) => {
        formData.append(`variants_id[${index}]`, car.vcq?.variant?.id);
        formData.append(`color[${index}]`, car.vcq?.color);
        formData.append(`quantity[${index}]`, car.vcq?.quantity);
        formData.append(`film_id[${index}]`, car.filmId);
        const price = parseInt(
          car.overallDimension?.variants[0]?.total_price || 0
        );
        total_price += price * parseInt(car.vcq?.quantity || 0);
        const days = parseInt(
          car.overallDimension?.variants[0]?.total_period || 0
        );
        total_days += days * parseInt(car.vcq?.quantity || 0);
        formData.append(`price[${index}]`, price);
        formData.append(`days[${index}]`, days);

        formData.append(`length[${index}]`, car.vcq?.size?.length || null);
        formData.append(`height[${index}]`, car.vcq?.size?.height || null);
      });

      formData.append(`total_price`, total_price);
      formData.append(`total_days`, total_days);

      this.blobs.forEach((b, i) => {
        const name = this.blobNames[i];
        formData.append(name, b, `${name}.png`);
      });

      try {
        await axios.post(
          `${config.API_URL}/constructor/saveFrom`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.isSent = true;
        this.$emit("sent");
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>

<style lang="scss" scoped>
#views {
  margin-bottom: 20px;
  display: flex;
  overflow-x: scroll;
  height: 148px;
  width: 390px;
  justify-content: flex-start;
}
</style>
